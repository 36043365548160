export const AuthEndPoints = {
  LOGIN: "auth/user-login",
};
export const DashBoardEndpoints = {};

export const UserEndpoints = {
  EMPLOYEE_GET_PROFILE: "employee/get-employee",
  EMPLOYEE_UPDATE_PROFILE: "employee/update-profile",
  EMPLOYEE_UPDATE_PASSWORD: "employee/update-password",
  GET_RESULTS: "employee/get-results",
};

export const TrainingEndpoints = {
  GET_TRAININGS_BY_DEPT_RANK: "employee/get-trainings",
  GET_TRAINING_BY_ID: "employee/get-training",
};

export const AssessmentEndpoints = {
  GET_ASSESSMENT_BY_ID_LEVEL: "employee/get-employee-exam",
  GET_ASSESSMENT_By_ID: "employee/get-questions/",
  STORE_EMPLOYEE_CHOICES: "employee/store-employee-choices",
};
