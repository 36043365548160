import React, { useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

interface InputFieldProps {
  label?: string;
  type: string;
  helpertext: string;
  placeholder?: string;
  password?: boolean;
}

const CustomInputField: React.FC<InputFieldProps> = (props) => {
  const { label, type, placeholder } = props;
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div className="w-full">
      <label className="capitalize" htmlFor={label}>
        {label}
      </label>{" "}
      <br />
      <div className={`border outline-none flex items-center rounded-lg w-full bg-inherit  mt-1  ${!isFocus ? "border-primary" : props?.helpertext ? "border-red-700" : " border-[#969696] shadow-3xl"}`}>
        <input
          className={`outline-none bg-inherit rounded w-full py-3 px-5  mt-1`}
          {...props}
          type={type}
          onFocus={(e) => {
            setIsFocus(true);
          }}
          onBlur={(e) => {
            setIsFocus(false);
          }}
          placeholder={placeholder}
        />
      </div>
      {props?.helpertext ? (
        <>
          <label className="text-[10px] text-red-500">{props?.helpertext}</label> <br />
        </>
      ) : null}
    </div>
  );
};

export default CustomInputField;
