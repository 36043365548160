import { createSlice } from "@reduxjs/toolkit";
import { CrispanQueryApi } from "api/QueryApi";
import { UsersApi } from "api/UsersApi";

interface AdminDetails {
  adminPayload: any;
  token: string | null;
  websocketData: any;
  webSocketDataList: any;
  otpPayload: any;
  userProfileData: any;
}

const initialState = {
  adminPayload: null,
  token: null,
  websocketData: [],
  webSocketDataList: null,
  otpPayload: null,
  userProfileData: null,
} as AdminDetails;

const AuthSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logOutUser: (state) => {
      state.token = null;
    },
    updateWebSocketData: (state, { payload }) => {
      state.websocketData = payload;
    },
    updateWebSocketDataList: (state, { payload }) => {
      state.webSocketDataList = payload;
    },
    setOtpPayload: (state, { payload }) => {
      state.otpPayload = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(CrispanQueryApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.adminPayload = payload;
        state.token = payload?.data?.access_token;
      })
      .addMatcher(UsersApi.endpoints.employeeGetProfile.matchFulfilled, (state, { payload }) => {
        state.userProfileData = payload;
      });
  },
});

export const { logOutUser, updateWebSocketData, updateWebSocketDataList, setOtpPayload } = AuthSlice.actions;
export default AuthSlice.reducer;
