import React, { useState } from "react";
import AuthWrapper from "common/AuthWrapper";
import { useFormik } from "formik";
import Logo from "assets/images/Logo.png";
import * as Yup from "yup";
import useAuth from "hooks/useAuth";
import ButtonLoader from "common/ButtonLoader";
import { Navigate, useNavigate } from "react-router-dom";
import { FaEyeSlash } from "react-icons/fa";
import toast from "react-hot-toast";
import { CrispanQueryApi } from "api/QueryApi";
import CustomInputField from "common/CustomInputField";
import GeneralButton from "common/GeneralButton";
import { getTextFieldFormikProps } from "utilities/utils";

const Login = () => {
  const token = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const loginData = [
    { id: 1, placeholder: "Email address", props: "email", icon: "", type: "text" },
    { id: 1, placeholder: "Password", props: "password", icon: "", type: "password", password: true },
  ];

  const [loginMutation, loginMutationResults] = CrispanQueryApi.useLoginMutation();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email or Phone number is required"),
      password: Yup.string().required("Password required"),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const result = await loginMutation(values).unwrap();
        console.log("login results", result);
      } catch (error: any) {
        console.log(error);

        toast.error(error?.data?.message);
      }
    },
  });

  return token ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <AuthWrapper>
      <div className="flex justify-center items-center h-full w-full">
        <div className="bg-white w-[39rem] shadow-lg rounded-[5px] py-[38px] px-[6px] flex flex-col items-center max-w-full">
          <img src={Logo} alt="" />
          <p className="text-[1.75rem] font-semibold">CRISPAN</p>
          <div className="text-center my-6"></div>
          <div className="w-full flex flex-col justify-center items-center">
            <form className="w-[85%] flex flex-col justify-center items-center" onSubmit={formik.handleSubmit} action="">
              <h2 className="font-medium text-2xl">Login to your dashboard</h2>
              <div className="w-full">
                {loginData.map((item, index) => {
                  return (
                    <div className="w-full" key={index}>
                      <CustomInputField type={item.type} placeholder={item.placeholder} password={item.password} {...getTextFieldFormikProps(formik, item.props)} />
                    </div>
                  );
                })}
                {/* <div className="flex w-full items-center text-sm justify-between pt-3 pb-5">
                  <div>
                    <span>Remember me</span>
                  </div>
                  <div className="text-[#F33905] cursor-pointer">Forgot Password?</div>
                </div> */}
              </div>
              <div className="w-[80%] flex flex-col justify-center items-center gap-y-[.5rem] mt-[2rem]">
                <GeneralButton loaderComponent={<ButtonLoader />} loading={loginMutationResults.isLoading} text="Login" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
