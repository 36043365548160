import { AssessmentEndpoints, TrainingEndpoints, UserEndpoints } from "constants/ApiEnumSets";
import { CrispanQueryApi } from "./QueryApi";

export const UsersApi = CrispanQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    userSubmitAnswers: builder.mutation({
      query: (body) => ({
        url: AssessmentEndpoints.STORE_EMPLOYEE_CHOICES,
        method: "POST",
        body,
      }),
    }),
    employeeGetProfile: builder.query({
      query: () => ({
        url: UserEndpoints.EMPLOYEE_GET_PROFILE,
        method: "GET",
      }),
      providesTags: ["EMPLOYEES"],
    }),
    employeeUpdateProfile: builder.mutation({
      query: (body) => ({
        url: UserEndpoints.EMPLOYEE_UPDATE_PROFILE,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["EMPLOYEES"],
    }),
    employeeUpdatePassword: builder.mutation({
      query: (body) => ({
        url: UserEndpoints.EMPLOYEE_UPDATE_PASSWORD,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["EMPLOYEES"],
    }),
    getResults: builder.query({
      query: () => ({
        url: UserEndpoints.GET_RESULTS,
        method: "GET",
      }),
    }),
    getTrainings: builder.query({
      query: () => ({
        url: TrainingEndpoints.GET_TRAININGS_BY_DEPT_RANK,
        method: "GET",
      }),
    }),
    getTrainingById: builder.query({
      query: ({ id }) => ({
        url: `${TrainingEndpoints.GET_TRAINING_BY_ID}/${id}`,
        method: "GET",
      }),
    }),
  }),
});
