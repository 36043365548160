import React from "react";

const GeneralButton = ({ text, onclick, loaderComponent, loading, type = "submit" }: { text: string | React.ReactElement; onclick?: () => void; loaderComponent?: React.ReactNode; loading?: boolean; type?: "submit" | "button" | undefined }) => {
  return (
    <div className="w-full">
      <button type={type} className="bg-gradient-to-br bg-[#C69638] flex items-center shrink-0 justify-center rounded-lg text-white py-[10px] px-[24px] w-full font-medium" onClick={onclick}>
        {loading ? loaderComponent : text}
      </button>
    </div>
  );
};

export default GeneralButton;
